import React from "react";
import HeroBanner from "./HeroBanner";
import Hero from "./Hero";
import Services from "./Services";
import CollaspeHero from "./CollaspeHero";
import Pricing from "./Pricing";
import CallToActionPlain from "./CallToActionPlain";
import FeatureBlock from "./FeatureBlock";
import BoldStatement from "./BoldStatement";
import HeadingStatment from "./HeadingStatement";
import VideoFeature from "./VideoFeature";
import HeadingBlock from "./HeadingBlock";
import CodeResources from "./CodeResources";
import Split from "./Split";
import SplitDown from "./SplitDown";
import VideoResources from "./VideoResources";
import Contact from "./Contact";
import Textblock from "./TextBlock";
import HeadingStandard from "./HeadingStandard";
import PortfolioSplit from "./PortfolioSplit";
import PortfolioSingle from "./PortfolioSingle";
import TextBanner from "./TextBanner";
import Markdown from "./Markdown";
import SimpleImage from "./SimpleImage";
import ColourBanner from "./ColourBanner";
import BlogSummary from "./BlogSummary";
import BlockBanner from "./BlockBanner";
import BlogBanner from "./BlogBanner";
import BlogImage from "./BlogImage";
import BlogText from "./BlogText";
import Heading from "./Heading";
import Divider from "./Divider";
import Triplet from "./Triplet";
import AnimatedFull from "./AnimatedFull";
import BlockBannerSub from "./BlockBannerSub";
import CallToAction from "./CallToAction";
import Samples from "./Samples";

export default function Sections({ sections, globals }) {
  return sections.map((s) => {
    switch (s.type) {
      case "HERO_BANNER":
        return <HeroBanner section={s} key={s.id} />;
      case "HERO":
        return <Hero section={s} key={s.id} />;
      case "MARKDOWN":
        return <Markdown section={s} key={s.id} />;
      case "COLLASPE_HERO":
        return <CollaspeHero section={s} key={s.id} />;
      case "SERVICES":
        return <Services section={s} key={s.id} />;
      case "PRICING":
        return <Pricing section={s} key={s.id} />;
      case "CALLTOACTION":
        return <CallToActionPlain section={s} key={s.id} />;
      case "FEATUREBLOCK":
        return <FeatureBlock section={s} key={s.id} />;
      case "TRIPLET":
        return <Triplet section={s} key={s.id} />;
      case "BOLDSTATEMENT":
        return <BoldStatement section={s} key={s.id} />;
      case "HEADINGSTATEMENT":
        return <HeadingStatment section={s} key={s.id} />;
      case "VIDEOFEATURE":
        return <VideoFeature section={s} key={s.id} />;
      case "HEADINGBLOCK":
        return <HeadingBlock section={s} key={s.id} />;
      case "RESOURCES":
        return <CodeResources section={s} key={s.id} />;
      case "VIDEO_RESOURCES":
        return <VideoResources section={s} key={s.id} />;
      case "CONTACT":
        return <Contact section={s} key={s.id} />;
      case "SPLIT":
        return <Split section={s} key={s.id} />;
      case "SPLIT_DOWN":
        return <SplitDown section={s} key={s.id} />;
      case "TEXTBLOCK":
        return <Textblock section={s} key={s.id} />;
      case "HEADINGSTANDARD":
        return <HeadingStandard section={s} key={s.id} />;
      case "TEXTBANNER":
        return <TextBanner section={s} key={s.id} />;
      case "PORTFOLIOSPLIT":
        return <PortfolioSplit section={s} key={s.id} />;
      case "PORTFOLIOSINGLE":
        return <PortfolioSingle section={s} key={s.id} />;
      case "SIMPLE_IMAGE":
        return <SimpleImage section={s} key={s.id} />;
      case "COLOUR_BANNER":
        return <ColourBanner section={s} key={s.id} />;
      case "BLOG_SUMMARY":
        return <BlogSummary section={s} key={s.id} globals={globals} />;
      case "BLOCK_BANNER":
        return <BlockBanner section={s} key={s.id} />;
      case "BLOCK_BANNER_SUB":
        return <BlockBannerSub section={s} key={s.id} />;
      case "BLOG_BANNER":
        return <BlogBanner section={s} key={s.id} />;
      case "BLOG_IMAGE":
        return <BlogImage section={s} key={s.id} />;
      case "BLOG_TEXT":
        return <BlogText section={s} key={s.id} />;
      case "HEADING":
        return <Heading section={s} key={s.id} />;
      case "DIVIDER":
        return <Divider section={s} key={s.id} />;
      case "ANIMATED_FULL":
        return <AnimatedFull section={s} key={s.id} />;
      case "CALL_TO_ACTION":
        return <CallToAction section={s} key={s.id} />;
      case "SAMPLES":
        return <Samples section={s} key={s.id} />;
      default:
        return null;
    }
  });
}
