export default function Samples({ section }) {
  const { items = [], title, description } = section;
  return (
    <section className="wrapper">
      <div className="border-top mt-150 pt-150 md-mt-100 md-pt-100 lg-mt-150 lg-pt-150">
        <div className="title-style-one text-center">
          <h2 className="title">{title}</h2>
          <p className="text-lg pt-30 lg-pt-20 preWrap">{description}</p>
        </div>
        <div className="portfolio-gallery-three">
          <div className="container">
            <div id="isotop-gallery-wrapper" className="d-flex flex-wrap">
              {items.map((item) => {
                const { image, title, description, link, tag, features = [] } = item;
                return (
                  <div className="isotop-item mt-150">
                    <div className="gallery-item mb-60 lg-mb-40">
                      <div className="img-holder">
                        <img src={image?.url} alt="" className="img-meta w-100 tran5s border rounded-3 p-3" />
                        <div className="expend tran3s d-flex align-items-center justify-content-center">
                          <a href={link} className="arrow tran3s me-2" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-arrow-up-right"></i>
                          </a>
                        </div>
                      </div>
                      <div className="caption pt-30 lg-pt-20">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <span class="tag">{tag}</span>
                            <h6>
                              <a href={link} target="_blank" rel="noopener noreferrer" className="pj-title">
                                {title}
                              </a>
                            </h6>
                            <p className="text-xs text-muted">{description}</p>
                            <div className="d-flex flex-wrap gap-2">
                              {features.map((feature) => (
                                <span className="badge bg-secondary">{feature}</span>
                              ))}
                            </div>
                            <a href={link} className="btn btn-primary mt-4" target="_blank" rel="noopener noreferrer">
                              View
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
